<template>
  <div class="link-us">
    <!-- 首页头部 -->
    <homeHeader :type="'linkUs'"></homeHeader>
    <LinkContent></LinkContent>
    <homeFooter></homeFooter>
    <!-- 联系我们二维码 -->
    <FollowPop :isShow="isShow"></FollowPop>
     <!-- 关注我们二维码 -->
    <linkUsPop :isShow="isShow1"></linkUsPop>
    <sideNav  @handleIsFollow="handleIsFollow" @handleIsFollow1="handleIsFollow1"></sideNav>
  </div>
</template>
<script>
import homeHeader from '../../components/homeHeader.vue'
import homeFooter from '../../components/homeFooter.vue'
import LinkContent from './components/LinkContent.vue'
import FollowPop from '../../components/FollowPop.vue'
import linkUsPop from '../../components/linkUsPop.vue'
import sideNav from '../../components/sideNav.vue'
export default {
  name:'',
  props:{},
  components:{homeHeader,homeFooter,LinkContent,sideNav,FollowPop,linkUsPop},
  data() {
    return {
      isShow: false,
      isShow1: false,
    }
  },
  methods: {
    handleIsFollow(bool) {
      this.isShow1 = false
      this.isShow = bool
    },
    handleIsFollow1(bool) {
      this.isShow = false
      this.isShow1= bool
    },
  },
  mounted() {}
}
</script>
<style scoped>
@media screen and (max-width: 480px) { 
  .link-us {
    background-color: #F2F2F2;
  }
}
</style>